<template>
  <div class="container">
    <div class="header">
      <h1>江苏移动尊享会员组队链接</h1>
    </div>
    <div class="content">
      <div class="info" v-html="info"></div>
      <div class="qrcode">
        <img :src="qrcode" alt="">
      </div>
      <div class="tip">
        <p>使用中国江苏移动APP扫描二维码或点击进入组队按钮进入组队页面</p>
      </div>
      <div class="url">
        <div class="url-button">
          <van-button type="primary" size="large" @click="openGroupPage" :disabled="loading">进入组队</van-button>
        </div>
        <div class="url-button">
          <van-button type="success" size="large" @click="fetchData">刷新链接</van-button>
        </div>
        <div class="url-button">
          <van-button type="danger" size="large" @click="showFeedbackPanel = true">问题反馈</van-button>
        </div>

        <div class="url-button">
          <van-button type="warning" size="large" @click="showAddPanel = true">提交链接</van-button>
        </div>
      </div>
    </div>
    <div class="guide">
      <div class="guide-box">


        <ul>
          <li>当前工具中收集组队链接{{ loading ? 'N/A' : data.total }}条。</li>
          <li>本工具中所有组队链接收集于互联网和用户提交。</li>
          <li>链接是随机获取的，点击刷新链接按钮可以切换链接。</li>
          <li>不保证链接的可用性，链接有问题可以用问题反馈提交。</li>
          <li>使用提交链接功能提交您的组队链接，有一定几率会被别人参与组队。</li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <p>Powered By Cloudflare Workers</p>
      <p>Developed By Kamino</p>
    </div>

    <van-action-sheet v-model:show="showFeedbackPanel" title="问题反馈">
      <div class="panel">
        <van-form @submit="handleFeedbackSubmit">
          <van-cell-group inset>
            <van-field name="error" label="链接错误">
              <template #input>
                <van-checkbox v-model="feedback.error" shape="square" />
              </template>
            </van-field>
            <van-field name="remove" label="申请删除">
              <template #input>
                <van-checkbox v-model="feedback.remove" shape="square" />
              </template>
            </van-field>
            <van-field v-model="feedback.problem" label="其他问题" placeholder="请填写您的问题" />
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
          <div v-if="!loading" class="token">
            <p>{{ data.token }}</p>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model:show="showAddPanel" title="提交链接">
      <div class="panel">
        <van-form @submit="handleAddSubmit">
          <van-cell-group inset>
            <van-field v-model="addurl" rows="2" autosize label="链接" type="textarea" placeholder="请输入您的邀请链接" />
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from 'qrcode'
import { Toast } from 'vant';
import { Notify } from 'vant';
import QrcodeLoading from "@/assets/qrcodeloading.jpg"

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      data: null,
      qrcode: QrcodeLoading,
      loading: true,
      addurl: null,
      info: '正在获取组队链接...',
      showFeedbackPanel: false,
      showAddPanel: false,
      feedback: {
        error: false,
        remove: false,
        problem: null,
        token: null,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Toast.loading({
        message: '正在获取链接',
        forbidClick: true,
        duration: 10000,
      });
      this.loading = true
      this.info = '正在获取组队链接...'
      this.qrcode = QrcodeLoading
      axios.get('/api/get').then(response => {
        this.data = response.data
        this.loading = false
        Toast.clear();
        Toast.success('链接获取成功');
        this.info = `该链接本周已被查看<span style="color:${this.data.count <= 10 ? 'green' : 'red'}">${this.data.count}</span>次`
        QRCode.toDataURL(this.data.url)
          .then(url => {
            this.qrcode = url
          })
          .catch(err => {
            console.error(err)
          })
      }).catch(error => {
        Toast.clear();
        Toast.fail('链接获取失败');
      })
    },
    openGroupPage() {
      if (!this.loading) {
        window.open(this.data.url, "_target")
      }
    },
    handleFeedbackSubmit() {
      Toast.loading({
        message: '正在提交反馈',
        forbidClick: true,
        duration: 10000,
      });
      this.feedback.token = this.data.token
      console.log(this.feedback)
      axios.post('/api/feedback', this.feedback).then(response => {
        Toast.clear();
        Toast.success(response.data.message);
      }).catch(error => {
        Toast.clear();
        Toast.success(error.response.data.message);
      }).finally(() => {
        this.feedback = {
          error: false,
          remove: false,
          problem: null,
          token: null,
        }
      })
    },
    handleAddSubmit() {
      if (this.addurl && this.addurl.length > 0) {
        Toast.loading({
          message: '正在提交链接',
          forbidClick: true,
          duration: 10000,
        });
        axios.post('/api/submit', {
          url: this.addurl
        }).then(response => {
          Toast.clear();
          Toast.success(response.data.message);
        }).catch(error => {
          Toast.clear();
          Toast.fail(error.response.data.message);
        }).finally(() => {
          this.addurl = null
        })
      } else {
        Notify({ type: 'warning', message: '请填写组队链接' });
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.header {
  text-align: center;
}

.content {
  text-align: center;
}

.url-button {
  width: 50%;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 5px;
}

.panel {
  min-height: 200px;
}

.qrcode {
  text-align: center
}

.qrcode img {
  width: 50%;
  max-width: 300px;
}

.tip p {
  font-size: 12px;
}

.footer {
  text-align: center;
  font-size: 12px;
}

.footer p {
  margin: 0;
  line-height: 20px;
}

.token {
  font-size: 10px;
  text-align: center;
}

.guide {
  margin-top: 10px;
}

.guide-box {
  width: 60%;
  max-width: 400px;
  font-size: 14px;
  margin: 0 auto;
}

.guide-box ul {
  list-style: square;
}
</style>
